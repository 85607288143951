import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { HttpError } from 'src/errors/HttpError';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { PO_FILE } from 'src/modules/config/codecs/PoFile';
import { Translations } from 'src/modules/config/types/Translations';
import { Language } from 'src/types/common/Language';
import { call } from 'typed-redux-saga';

export function* loadTranslations(language: Language): SagaIterator<Translations> {
  try {
    const response = yield* call(fetch, `/translations/${encodeURIComponent(language)}.po`, {
      method: 'GET',
      redirect: 'error',
      credentials: 'omit',
    });
    if (!response.ok) {
      throw new HttpError(response);
    }

    const content = yield* call({
      fn: response.text,
      context: response,
    });
    return yield* call(
      decodeOrThrow,
      PO_FILE,
      content,
    );
  } catch (error) {
    throw new RuntimeError('Could not fetch environment file', {}, NativeError.wrap(error));
  }
}
