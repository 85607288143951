import './BuildingInfoFormModal.scss';
import { COUNTRY_CODES, AreaUnit, BuildingType, CountryCode } from '@belimo-retrofit-portal/logic';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import { FormController } from '@form-ts/core';
import { useForm, useFormWatch } from '@form-ts/react';
import { constNull } from 'fp-ts/function';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AreaUnitView } from 'src/modules/common/components/AreaUnitView';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { LOCALE_MAP } from 'src/modules/common/constants/locale';
import { BUILDING_TYPE_OPTIONS, UNIT_VERSION_OPTIONS } from 'src/modules/common/constants/options';
import { CARBON_THEME } from 'src/modules/common/constants/theme';
import { ArrowLeftIcon } from 'src/modules/common/icons/ArrowLeftIcon';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { AutocompleteField } from 'src/modules/form/components/AutocompleteField';
import { DecimalField } from 'src/modules/form/components/DecimalField';
import { DropdownField } from 'src/modules/form/components/DropdownField';
import { TextField } from 'src/modules/form/components/TextField';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { ensureNonNull } from 'src/modules/form/utils/transform';
import { BUILDING_SCHEMA } from 'src/modules/project-new/constants/schema';
import { BuildingFormData } from 'src/modules/project-new/types/BuildingFormData';
import { Language } from 'src/types/common/Language';

type Props = {
  readonly language: Language;
  readonly formData: BuildingFormData;
  readonly onSubmit: (form: FormController<BuildingFormData, FormError>) => void;
  readonly onBack: (form: FormController<BuildingFormData, FormError>) => void;
  readonly onClose: () => void;
};

export const BuildingInfoFormModal = ({ language, formData, onSubmit, onBack, onClose }: Props): React.ReactElement => {
  const intl = useIntl();

  const form = useForm<BuildingFormData, FormError>('project.create.new.buidling', {
    reinitialize: false,
    initialValues: formData,
  });
  useFormValidator(form, BUILDING_SCHEMA);

  const handleSubmit = useCallback((): void => {
    onSubmit(form);
  }, [form, onSubmit]);
  const handleBack = useCallback((): void => {
    onBack(form);
  }, [form, onBack]);

  const countryCodesFilteredByLanguage = useMemo(
    () => COUNTRY_CODES.map((code) => ({
      label: intl.formatMessage({ id: `common/country/name/${code}` }),
      value: code,
    }))
      .sort((optionA, optionB) => new Intl.Collator(LOCALE_MAP[language]).compare(optionA.label, optionB.label)),
    [intl, language],
  );

  const isSubmitting = useFormWatch(form, form.submitting.get);

  return (
    <>
      <ModalHeader
        className="bp-project-create-modal__header"
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        label={intl.formatMessage({ id: 'project/create/building/title' })}
        title={intl.formatMessage({ id: 'project/create/building/subTitle' })}
        closeModal={onClose}
      />

      <ModalBody className="bp-project-create-modal__body">
        <div className="bp-building-info-form-modal__row bp-building-info-form-modal__row--grid">
          <DropdownField
            size="lg"
            options={BUILDING_TYPE_OPTIONS}
            field={form.field.at('type')}
            titleText={(
              <>
                {intl.formatMessage({ id: 'basedata/building/type' })}
                <span style={{ color: CARBON_THEME.belimoOrangeColor }}>*</span>
              </>
            )}
            itemToElement={renderBuildingTypeOption}
            renderSelectedItem={renderBuildingTypeOption}
          />

          <DecimalField
            size="lg"
            theme="gray"
            field={form.field.at('size')}
            labelText={intl.formatMessage({ id: 'basedata/building/size' })}
            autoComplete="off"
          />

          <DropdownField
            size="lg"
            options={UNIT_VERSION_OPTIONS}
            field={form.field.at('unit').transform(ensureNonNull())}
            titleText={intl.formatMessage({ id: 'basedata/building/unit' })}
            itemToElement={renderUnitOptions}
            renderSelectedItem={renderUnitOptions}
          />
        </div>

        <div className="bp-building-info-form-modal__row">
          <TextField
            size="lg"
            field={form.field.at('address').at('street')}
            labelText={intl.formatMessage({ id: 'basedata/building/address/street' })}
            autoComplete="off"
            maxLength={30}
            renderHint={constNull}
          />
        </div>

        <div className="bp-building-info-form-modal__row">
          <TextField
            size="lg"
            field={form.field.at('address').at('city')}
            labelText={intl.formatMessage({ id: 'basedata/building/address/city' })}
            autoComplete="off"
            maxLength={20}
            renderHint={constNull}
          />
        </div>

        <div className="bp-building-info-form-modal__row">
          <AutocompleteField
            size="lg"
            direction="top"
            field={form.field.at('address').at('country')}
            titleText={(
              <>
                <FormattedMessage id="basedata/building/address/country"/>
                <span style={{ color: CARBON_THEME.belimoOrangeColor }}>*</span>
              </>
            )}
            options={countryCodesFilteredByLanguage}
            itemToElement={renderCountryOption}
          />

          <TextField
            size="lg"
            field={form.field.at('address').at('zip')}
            labelText={intl.formatMessage({ id: 'basedata/building/address/zipCode' })}
            autoComplete="off"
            maxLength={10}
            renderHint={constNull}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <CustomButton
          className="bp-project-create-modal__button"
          kind="secondary"
          onClick={handleBack}
        >
          <div className="bp-project-create-modal__button-icon">
            <ArrowLeftIcon/>
          </div>

          <FormattedMessage id="project/create/action/back"/>
        </CustomButton>

        <CustomButton
          disabled={isSubmitting}
          kind="primary"
          onClick={handleSubmit}
          autoFocus={true}
          loading={isSubmitting}
          renderIcon={() => (
            <div className="bp-project-create-modal__button-icon">
              <ArrowRightIcon/>
            </div>
          )}
        >
          <FormattedMessage id="project/create/action/create"/>
        </CustomButton>
      </ModalFooter>
    </>
  );
};

function renderBuildingTypeOption(option: BuildingType): React.ReactElement {
  return <FormattedMessage id={`common/buildingType/${option}`}/>;
}

function renderUnitOptions(option: AreaUnit): React.ReactElement {
  return <AreaUnitView unit={option}/>;
}

function renderCountryOption(option: CountryCode): React.ReactElement {
  return <FormattedMessage id={`common/country/name/${option}`}/>;
}
