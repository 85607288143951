import './LoginScreen.scss';
import { FormController } from '@form-ts/core';
import { useForm, useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import { constNull } from 'fp-ts/function';
import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useAction } from 'src/hooks/useAction';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { TERMS_AND_PRIVACY_LINK_MAP } from 'src/modules/common/constants/links';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { BodySmallText } from 'src/modules/common/typography/BodySmallText';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';
import { CheckboxField } from 'src/modules/form/components/CheckboxField';
import { PasswordField } from 'src/modules/form/components/PasswordField';
import { TextField } from 'src/modules/form/components/TextField';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { LOGIN_PERFORM } from 'src/modules/login/actions/LoginActions';
import { LOGIN_FORM_SCHEMA } from 'src/modules/login/constants/schema';
import { LoginFormData } from 'src/modules/login/types/LoginFormData';
import { RequestAccessFormData } from 'src/modules/login/types/RequestAccessFormData';
import { RequestAccessModal } from 'src/modules/login/views/RequestAccessModal';
import { Language } from 'src/types/common/Language';

type Props = {
  readonly switchLanguage: (language: Language) => void;
  readonly submitRequestAccess: (form: FormController<RequestAccessFormData, FormError>) => void;
};

export const LoginScreen = ({ switchLanguage, submitRequestAccess }: Props): React.ReactElement => {
  const requestAccessButtonRef = useRef<HTMLButtonElement | null>(null);

  const [isRequestAccessModalOpen, setRequestAccessModalOpen] = useState(false);
  const closeRequestForm = useCallback(() => setRequestAccessModalOpen(false), []);

  const intl = useIntl();

  const form = useForm<LoginFormData, FormError>('login', {
    reinitialize: false,
    initialValues: {
      username: '',
      password: '',
      remember: true,
    },
  });

  useFormValidator(form, LOGIN_FORM_SCHEMA);

  const formErrors = useFormWatch(form, form.errors.get);
  const isSubmitting = useFormWatch(form, form.submitting.get);
  const isSubmissionFailed = (
    !isSubmitting &&
    Object.keys(formErrors).length > 0 &&
    Object.values(formErrors).every((error) => error?.code === 'invalid')
  );

  const submitForm = useAction(LOGIN_PERFORM.request);
  const handleSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault();
    submitForm(form);
  }, [form, submitForm]);

  const switchLanguageToEn = useCallback(() => switchLanguage('en'), [switchLanguage]);
  const switchLanguageToDe = useCallback(() => switchLanguage('de'), [switchLanguage]);

  const language = useSelector(getLanguage);
  const termsAndPrivacyLink = TERMS_AND_PRIVACY_LINK_MAP[language];

  return (
    <>
      <div className="bp-login-screen">
        <div className="bp-login-screen__form-wrapper">
          <div className="bp-login-screen__language-wrapper">
            <button
              type="button"
              className={clsx(
                'bp-login-screen__language-switch',
                {
                  'bp-login-screen__language-switch--selected': language === 'en',
                },
              )}
              onClick={switchLanguageToEn}
              aria-label={intl.formatMessage({ id: 'login/language/en' })}
            >
              <FormattedMessage id="login/language/en"/>
            </button>

            <button
              type="button"
              className={clsx(
                'bp-login-screen__language-switch',
                {
                  'bp-login-screen__language-switch--selected': language === 'de',
                },
              )}
              onClick={switchLanguageToDe}
              aria-label={intl.formatMessage({ id: 'login/language/de' })}
            >
              <FormattedMessage id="login/language/de"/>
            </button>
          </div>

          <div className="bp-login-screen__form-header">
            <h4 className="bp-login-screen__title">
              <FormattedMessage id="login/title"/>
            </h4>

            <div className="bp-login-screen__description-wrapper">
              <BodySmallText className="bp-login-screen__greeting">
                <FormattedMessage id="login/description"/>
              </BodySmallText>

              <button
                className="bp-login-screen__request-access-button"
                type="button"
                onClick={() => setRequestAccessModalOpen(true)}
                aria-label={intl.formatMessage({ id: 'login/requestAccess/button' })}
              >
                <FormattedMessage id="login/requestAccess/button"/>
              </button>
            </div>
          </div>

          <div className="bp-login-screen__form-content">
            <form
              className="bp-login-screen__form"
              noValidate={true}
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <TextField
                wrapperClass="bp-login-screen__field--email"
                field={form.field.at('username')}
                renderError={(error): React.ReactNode => (
                  error && error.code !== 'invalid'
                    ? <FormattedMessage id={`form/error/${error.code}`} values={error.context}/>
                    : ' '
                )}
                labelText={intl.formatMessage({ id: 'login/username' })}
                type="email"
                size="md"
                disabled={isSubmitting}
                autoFocus={true}
                autoComplete="username"
                placeholder={intl.formatMessage({ id: 'login/emailPlaceholder' })}
                spellCheck={false}
                autoCorrect="off"
                autoCapitalize="off"
                renderHint={constNull}
              />

              <PasswordField
                wrapperClass="bp-login-screen__field--password"
                field={form.field.at('password')}
                renderError={(error): React.ReactNode => (
                  error && error.code !== 'invalid'
                    ? <FormattedMessage id={`form/error/${error.code}`} values={error.context}/>
                    : ' '
                )}
                labelText={intl.formatMessage({ id: 'login/password' })}
                type="password"
                size="lg"
                disabled={isSubmitting}
                autoComplete="current-password"
                placeholder={intl.formatMessage({ id: 'login/passwordPlaceholder' })}
                spellCheck={false}
                autoCorrect="off"
                autoCapitalize="off"
              />

              <p className="bp-login-screen__form-error">
                {isSubmissionFailed ? (<FormattedMessage id="login/invalidCredentials"/>) : null}
              </p>

              <div className="bp-login-screen__form-extra">
                <div className="bp-login-screen__form-remember">
                  <CheckboxField
                    labelText={<FormattedMessage id="login/rememberMe"/>}
                    field={form.field.at('remember')}
                  />
                </div>
                <div className="bp-login-screen__form-forget">
                  <RouterLink to="/password/forgot" className="bp-login-screen__form-help-link">
                    <FormattedMessage id="login/forgotPassword"/>
                  </RouterLink>
                </div>
              </div>

              <CustomButton
                className="bp-login-screen__button-submit"
                disabled={isSubmitting}
                kind="primary"
                loading={isSubmitting}
                type="submit"
                size="md"
                renderIcon={() => (
                  <div className="bp-login-screen__button-icon">
                    <ArrowRightIcon/>
                  </div>
                )}
              >
                <FormattedMessage id="login/submit"/>
              </CustomButton>

              <p className="bp-login-screen__terms-and-privacy">
                <FormattedMessage
                  id="login/termsAndPrivacy"
                  values={{ link: link(termsAndPrivacyLink) }}
                />
              </p>

              <p className="bp-login-screen__form-help">
                <FormattedMessage id="login/needHelpMsg" values={{ mailTo }}/>
              </p>
            </form>
          </div>
        </div>
      </div>

      <CustomModal
        launcherButtonRef={requestAccessButtonRef}
        open={isRequestAccessModalOpen}
        onClose={closeRequestForm}
        size="md"
        isShaded={false}
        shouldUnmount={true}
      >
        <RequestAccessModal
          onClose={closeRequestForm}
          onSubmit={submitRequestAccess}
        />
      </CustomModal>
    </>
  );
};

const mailTo = (children: React.ReactNode): React.ReactElement => (
  <a
    className="bp-login-screen__form-help-link"
    href="mailto:retrofit.feedback@belimo.de"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

const link = (termsAndPrivacyLink: string) => (children: React.ReactNode): React.ReactNode => (
  <a
    className="bp-login-screen__form-help-link"
    href={termsAndPrivacyLink}
    target="_blank"
    rel="noreferrer"
  >{children}
  </a>
);
