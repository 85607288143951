import { DATE_TIME, PROJECT_DATA, UUID } from '@belimo-retrofit-portal/logic';
import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { Project } from 'src/modules/common/types/Project';
import { ProjectOwner } from 'src/modules/common/types/ProjectOwner';

const PROJECT_OWNER: C.Codec<unknown, Json, ProjectOwner> = C.struct({
  id: UUID,
  email: C.string,
  firstName: C.string,
  lastName: C.string,
});

export const PROJECT: C.Codec<unknown, Json, Project> = C.struct({
  id: UUID,
  data: PROJECT_DATA,
  owner: C.nullable(PROJECT_OWNER),
  createdAt: DATE_TIME,
  updatedAt: DATE_TIME,
  isShared: C.boolean,
});
