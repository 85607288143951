import { Language } from 'src/types/common/Language';
import { Locale } from 'src/types/common/Locale';

export const LOCALE_MAP = {
  en: 'en-us',
  de: 'de-ch',
} satisfies Record<Language, Locale>;

export const SUPPORTED_LOCALES: ReadonlyMap<string, Locale> = new Map([
  ['en', 'en-us'],
  ['de', 'de-de'],
  ['fr', 'fr-fr'],

  ['en-au', 'en-au'],
  ['en-ca', 'en-ca'],
  ['en-gb', 'en-gb'],
  ['en-us', 'en-us'],

  ['de-at', 'de-at'],
  ['de-de', 'de-de'],
  ['de-li', 'de-li'],
  ['de-lu', 'de-lu'],

  ['de-ch', 'de-ch'],
  ['fr-be', 'fr-be'],
  ['fr-ca', 'fr-ca'],
  ['fr-fr', 'fr-fr'],
  ['fr-lu', 'fr-lu'],
  ['fr-ch', 'fr-ch'],
]);
