import { LogicError } from '@belimo-retrofit-portal/logic';
import IntlMessageFormat from 'intl-messageformat';
import { createSelector } from 'reselect';
import { LOCALE_MAP } from 'src/modules/common/constants/locale';
import { getConfigData } from 'src/modules/config/selectors/getConfigData';
import { Translator } from 'src/modules/config/types/Translator';

export const getTranslator = createSelector([
  getConfigData,
], (config): Translator => (key, values) => {
  const { language, translations } = config.i18n;

  const message = translations[key];
  if (!message) {
    throw new LogicError(
      `Missing translation key "${key}" for "${language}" language`,
      { key, values },
    );
  }

  const locale = LOCALE_MAP[language];
  return new IntlMessageFormat(message, locale).format<string>(values).toString();
});
