import { Language } from 'src/types/common/Language';

export function getPreferredLanguage(): Language {
  const language = navigator.language.toLowerCase();

  if (language === 'de' || language.startsWith('de-')) {
    return 'de';
  }

  return 'en';
}
