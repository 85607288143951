import './HvacResultSavingsSystem.scss';
import { EnergySource, HvacArea, HvacResultsArea } from '@belimo-retrofit-portal/logic';
import { Stack } from '@carbon/react';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CarbonSavingsUnit } from 'src/modules/common/components/CarbonSavingsUnit';
import { EnergyEfficiencyStack } from 'src/modules/common/components/EnergyEfficiencyStack';
import { EnergySavingsUnit } from 'src/modules/common/components/EnergySavingsUnit';
import { SavingsAmount } from 'src/modules/common/components/SavingsAmount';
import { SavingsValue } from 'src/modules/common/components/SavingsValue';
import { SavingTileCard } from 'src/modules/common/components/SavingTileCard';
import { sub, sup } from 'src/modules/common/constants/formattingHelpers';
import { HeatingIcon } from 'src/modules/common/icons/HeatingIcon';
import { LightningIcon } from 'src/modules/common/icons/LightningIcon';
import { BodySmallText } from 'src/modules/common/typography/BodySmallText';
import { HeadlineSmallText } from 'src/modules/common/typography/HeadlineSmallText';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormData } from 'src/modules/hvac/types/HvacFormData';

type Props = {
  readonly form: FormController<HvacFormData, FormError>;
  readonly results: HvacResultsArea;
};

export const HvacResultSavingsSystem = ({ form, results }: Props): React.ReactElement => {
  const intl = useIntl();
  const currency = useFormWatch(form, form.field.at('currency').value.get);

  return (
    <Stack orientation="vertical" gap="1rem" className="bp-hvac-result-savings-system">
      <div className="bp-hvac-result-savings-system__assessments">
        <div className="bp-hvac-result-savings-system__assessment">
          <div className="bp-hvac-result-savings-system__assessment-text">
            <HeadlineSmallText className="bp-hvac-result-savings-system__assessment-title">
              <FormattedMessage id="hvac/result/savings/efficiency/actual/title"/>
            </HeadlineSmallText>

            <BodySmallText className="bp-hvac-result-savings-system__assessment-description">
              <FormattedMessage id="hvac/result/savings/efficiency/actual/description"/>
            </BodySmallText>
          </div>

          <div className="bp-hvac-result-savings-system__assessment-stack">
            <EnergyEfficiencyStack
              line={false}
              value={results.rating.actual}
            />
          </div>
        </div>

        <div className="bp-hvac-result-savings-system__assessment">
          <div className="bp-hvac-result-savings-system__assessment-text">
            <HeadlineSmallText className="bp-hvac-result-savings-system__assessment-title">
              <FormattedMessage id="hvac/result/savings/efficiency/future/title"/>
            </HeadlineSmallText>

            <BodySmallText className="bp-hvac-result-savings-system__assessment-description">
              <FormattedMessage id="hvac/result/savings/efficiency/future/description"/>
            </BodySmallText>
          </div>

          <div className="bp-hvac-result-savings-system__assessment-stack">
            <EnergyEfficiencyStack
              line={false}
              value={results.rating.future}
            />
          </div>
        </div>
      </div>

      <div className="bp-hvac-result-savings-system__cards">
        {results.type !== HvacArea.VENTILATION && (
          <SavingTileCard
            amount={(
              <SavingsValue
                value={results.savings.thermal.relative?.mul(100) ?? null}
                precision={0}
              />
            )}
            amountTitle={intl.formatMessage({ id: 'hvac/result/savings/potential/thermalSavings/diagram/label' })}
            icon={<HeatingIcon/>}
            savingTitle={intl.formatMessage({ id: 'hvac/result/savings/potential/thermalSavings/title' })}
            unit={<FormattedMessage id="unit/percent"/>}
            small={false}
          >
            <div className="bp-hvac-result-savings-overall__saving-area">
              <div className="bp-hvac-result-savings-overall__saving-description">
                <FormattedMessage
                  id="hvac/result/savings/potential/thermalSavings/energy"
                  values={{ sub, sup }}
                />
              </div>

              <div className="bp-hvac-result-savings-overall__saving-value">
                <SavingsValue
                  value={results.savings.thermal.absolute?.div(1_000) ?? null}
                  precision={0}
                />
              </div>
              <div className="bp-hvac-result-savings-overall__saving-unit">
                <EnergySavingsUnit source={results.savings.thermal.source}/>
              </div>
            </div>

            <div className="bp-hvac-result-savings-overall__saving-area">
              <div className="bp-hvac-result-savings-overall__saving-description">
                <FormattedMessage
                  id="hvac/result/savings/potential/thermalSavings/annual"
                  values={{ sub, sup }}
                />
              </div>

              <div className="bp-hvac-result-savings-overall__saving-value">
                <SavingsAmount
                  value={results.savings.thermal.annual}
                  currency={currency}
                />
              </div>
            </div>
          </SavingTileCard>
        )}

        <SavingTileCard
          amount={(
            <SavingsValue
              value={results.savings.electrical.relative?.mul(100) ?? null}
              precision={0}
            />
          )}
          amountTitle={intl.formatMessage({ id: 'hvac/result/savings/potential/electricalSavings/diagram/label' })}
          icon={<LightningIcon/>}
          savingTitle={intl.formatMessage({ id: 'hvac/result/savings/potential/electricalSavings/title' })}
          unit={<FormattedMessage id="unit/percent"/>}
          small={false}
        >
          <div className="bp-hvac-result-savings-overall__saving-area">
            <div className="bp-hvac-result-savings-overall__saving-description">
              <FormattedMessage
                id="hvac/result/savings/potential/electricalSavings/energy"
                values={{ sub, sup }}
              />
            </div>

            <div className="bp-hvac-result-savings-overall__saving-value">
              <SavingsValue
                value={results.savings.electrical.absolute?.div(1_000) ?? null}
                precision={0}
              />
            </div>
            <div className="bp-hvac-result-savings-overall__saving-unit">
              <EnergySavingsUnit source={EnergySource.ELECTRICITY}/>
            </div>
          </div>

          <div className="bp-hvac-result-savings-overall__saving-area">
            <div className="bp-hvac-result-savings-overall__saving-description">
              <FormattedMessage
                id="hvac/result/savings/potential/electricalSavings/annual"
                values={{ sub, sup }}
              />
            </div>

            <div className="bp-hvac-result-savings-overall__saving-value">
              <SavingsAmount
                value={results.savings.electrical.annual}
                currency={currency}
              />
            </div>
          </div>
        </SavingTileCard>

        <SavingTileCard
          amount={(
            <SavingsValue
              value={results.savings.carbon.relative?.mul(100) ?? null}
              precision={0}
            />
          )}
          amountTitle={intl.formatMessage({ id: 'hvac/result/savings/potential/carbonDioxide/diagram/label' }, { sub })}
          icon={<LightningIcon/>}
          savingTitle={intl.formatMessage({ id: 'hvac/result/savings/potential/carbonDioxide/title' }, { sub })}
          unit={<FormattedMessage id="unit/percent"/>}
          small={false}
        >
          {results.type !== HvacArea.VENTILATION && (
            <div className="bp-hvac-result-savings-overall__saving-area">
              <div className="bp-hvac-result-savings-overall__saving-description">
                <FormattedMessage
                  id="hvac/result/savings/potential/carbonDioxide/thermal"
                  values={{ sub, sup }}
                />
              </div>

              <div className="bp-hvac-result-savings-overall__saving-value">
                <SavingsValue
                  value={results.savings.carbon.thermal?.div(1_000_000) ?? null}
                  precision={0}
                />
              </div>
              <div className="bp-hvac-result-savings-overall__saving-unit">
                <CarbonSavingsUnit/>
              </div>
            </div>
          )}

          <div className="bp-hvac-result-savings-overall__saving-area">
            <div className="bp-hvac-result-savings-overall__saving-description">
              <FormattedMessage
                id="hvac/result/savings/potential/carbonDioxide/electrical"
                values={{ sub, sup }}
              />
            </div>

            <div className="bp-hvac-result-savings-overall__saving-value">
              <SavingsValue
                value={results.savings.carbon.electrical?.div(1_000_000) ?? null}
                precision={0}
              />
            </div>
            <div className="bp-hvac-result-savings-overall__saving-unit">
              <CarbonSavingsUnit/>
            </div>
          </div>
        </SavingTileCard>
      </div>
    </Stack>
  );
};
