import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrGet } from 'src/io/utils/decodeOrGet';
import { LANGUAGE } from 'src/modules/common/codecs/Language';
import { getPreferredLanguage } from 'src/modules/common/utils/language';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { storageGet } from 'src/sagas/utils/storage';
import { Language } from 'src/types/common/Language';
import { call } from 'typed-redux-saga';

export function* loadLanguage(): SagaIterator<Language> {
  const preferred = yield* call(getPreferredLanguage);

  try {
    yield* call(logDebug, 'Loading user language...');

    const stored = yield* call(storageGet, 'language');
    const language = yield* call(decodeOrGet, LANGUAGE, stored, preferred);

    yield* call(logDebug, 'Loading user language... done', language);
    return language;
  } catch (error) {
    yield* call(logError, 'Loading user language... error', error);
    yield* call(sentryCatch, new RuntimeError('Could not load user language', {}, NativeError.wrap(error)));

    return preferred;
  }
}
