import './SchemaCanvas.scss';
import { Matrix } from '@belimo-retrofit-portal/logic';
import { ZoomIn, ZoomOut, Maximize, Minimize, ZoomReset } from '@carbon/icons-react';
import { ModalBody, ModalFooter, ModalHeader, Tooltip } from '@carbon/react';
import { pipe } from 'fp-ts/function';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { PreserveContent } from 'src/modules/common/components/PreserveContent';
import { MATRIX_IDENTITY } from 'src/modules/common/constants/matrix';
import { Point } from 'src/modules/common/types/Point';
import { bind } from 'src/modules/common/utils/decorator';
import { INTERPOLATION_MATRIX } from 'src/modules/common/utils/interpolation';
import { matrixInverse, matrixMultiply, matrixScale, matrixTranslate } from 'src/modules/common/utils/matrix';
import { pointTransform } from 'src/modules/common/utils/point';
import { spring } from 'src/modules/common/utils/spring';
import { ConfigSchema } from 'src/modules/config/types/ConfigSchema';
import { SchemaAssignment } from 'src/modules/schema/types/SchemaAssignment';
import { SchemaContainer } from 'src/modules/schema/types/SchemaContainer';
import { SchemaDevice } from 'src/modules/schema/types/SchemaDevice';
import { SchemaEventCanvas, SchemaEventCanvasMove, SchemaEventCanvasZoom } from 'src/modules/schema/types/SchemaEvent';
import { SchemaAssignments } from 'src/modules/schema/views/canvas/SchemaAssignments';
import { SchemaBackground } from 'src/modules/schema/views/canvas/SchemaBackground';
import { SchemaContainerPreview } from 'src/modules/schema/views/SchemaContainerPreview';
import { SchemaContainerSelect } from 'src/modules/schema/views/SchemaContainerSelect';
import { SchemaGesture } from 'src/modules/schema/views/SchemaGesture';
import { SchemaInputLayer } from 'src/modules/schema/views/SchemaInputLayer';

type Props = {
  readonly schema: ConfigSchema;
  readonly assignments: ReadonlyArray<SchemaAssignment>;
  readonly schemaMatrix: Matrix;
  readonly onChange: (assignments: ReadonlyArray<SchemaAssignment>) => void;
  readonly onChangeSchemaMatrix: (matrix: Matrix) => void;
};
type State = {
  readonly matrix: Matrix;
  readonly modal: SchemaAssignment | null;
  readonly preview: AssignedContainer | null;
  readonly isFullScreen: boolean;
};
type AssignedContainer = {
  readonly container: SchemaContainer;
  readonly selection: SchemaDevice;
};

class SchemaCanvasWithContext extends React.Component<Props & WrappedComponentProps<'intl'>, State> {
  private readonly animation = spring({
    mass: 1,
    tension: 1000,
    friction: 65,

    // eslint-disable-next-line react/destructuring-assignment
    initialPosition: this.props.schemaMatrix,
    initialVelocity: { a: 0, b: 0, c: 0, d: 0, e: 0, f: 0 },

    onChange: (matrix, completed) => {
      this.setState({ matrix });

      if (completed) {
        // eslint-disable-next-line react/destructuring-assignment
        this.props.onChangeSchemaMatrix(matrix);
      }
    },

    precision: 0.001,
    interpolation: INTERPOLATION_MATRIX,
  });

  public readonly state: State = {
    // eslint-disable-next-line react/destructuring-assignment
    matrix: this.props.schemaMatrix,
    modal: null,
    preview: null,
    isFullScreen: false,
  };

  public render(): React.ReactElement {
    const { isFullScreen } = this.state;

    return (
      <>
        <PreserveContent>
          {isFullScreen
            ? null
            : this.renderContent()}
        </PreserveContent>

        {this.renderModals()}
      </>
    );
  }

  private renderContent(): React.ReactElement {
    const { schema } = this.props;
    const { matrix: { a: zoom } } = this.state;

    return (
      <div className="bp-schema-canvas">
        <div className="bp-schema-canvas__wrapper">
          {
            zoom > 1
              ? (
                <div className="bp-schema-canvas__hint">
                  <FormattedMessage id="schema/zoomMessage"/>
                </div>
              )
              : null
          }

          <SchemaGesture
            width={schema.width}
            height={schema.height}
            onEvent={this.handleCanvasEvent}
          >
            {this.renderCanvas()}
          </SchemaGesture>
        </div>
        <div className="bp-schema-canvas__controls">
          {this.renderControls()}
        </div>
      </div>
    );
  }

  private renderCanvas(): React.ReactElement {
    const { matrix } = this.state;
    const { schema, assignments } = this.props;

    const transformLine = [
      matrix.a.toFixed(5),
      matrix.b.toFixed(5),
      matrix.c.toFixed(5),
      matrix.d.toFixed(5),
      matrix.e.toFixed(5),
      matrix.f.toFixed(5),
    ].join(',');

    return (
      <div
        className="bp-schema-canvas__content"
        style={{ transform: `matrix(${transformLine})`, width: schema.width, height: schema.height }}
      >
        <svg
          className="bp-schema-canvas__chart"
          viewBox={`0 0 ${schema.width} ${schema.height}`}
          width={schema.width}
          height={schema.height}
        >
          <SchemaBackground
            schema={schema}
          />
          <SchemaAssignments
            assignments={assignments}
          />
        </svg>
        <div className="bp-schema-canvas__layer">
          <SchemaInputLayer
            assignments={assignments}
            onSelect={this.handleContainerSelect}
          />
        </div>
      </div>
    );
  }

  private renderControls(): React.ReactElement {
    const { matrix, isFullScreen } = this.state;
    const { intl } = this.props;
    const zoomFactor = Math.round(matrix.a * 100);

    return (
      <div className="bp-schema-canvas__controls">
        <div className="bp-schema-canvas__control-group">
          <CustomButton
            className="bp-schema-canvas__button"
            kind="ghost"
            disabled={zoomFactor <= ZOOM_MIN * 100}
            onClick={this.handleZoomOut}
            hasIconOnly={true}
            iconDescription={intl.formatMessage({ id: 'schema/canvas/control/zoomOut' })}
            tooltipPosition="bottom"
          >
            <ZoomOut/>
          </CustomButton>

          <Tooltip
            leaveDelayMs={0}
            className="bp-schema-canvas__zoom-wrapper"
            align="bottom"
            description={intl.formatMessage({ id: 'schema/canvas/control/zoomFactor' })}
          >
            <p className="bp-schema-canvas__zoom">
              {zoomFactor}%
            </p>
          </Tooltip>

          <CustomButton
            className="bp-schema-canvas__button"
            kind="ghost"
            disabled={zoomFactor >= ZOOM_MAX * 100}
            onClick={this.handleZoomIn}
            hasIconOnly={true}
            iconDescription={intl.formatMessage({ id: 'schema/canvas/control/zoomIn' })}
            tooltipPosition="bottom"
          >
            <ZoomIn/>
          </CustomButton>
        </div>

        <CustomButton
          className="bp-schema-canvas__button"
          kind="ghost"
          onClick={this.handleZoomReset}
          hasIconOnly={true}
          iconDescription={intl.formatMessage({ id: 'schema/canvas/control/zoomReset' })}
          tooltipPosition="bottom"
        >
          <ZoomReset/>
        </CustomButton>

        <CustomButton
          className="bp-schema-canvas__button"
          kind="ghost"
          onClick={isFullScreen ? this.handleFullScreenClose : this.handleFullScreenOpen}
          hasIconOnly={true}
          iconDescription={intl.formatMessage({ id: 'schema/canvas/control/fullScreen' })}
          tooltipPosition="bottom"
        >
          {isFullScreen ? <Minimize/> : <Maximize/>}
        </CustomButton>
      </div>
    );
  }

  private renderModals(): React.ReactElement {
    const { modal, preview, isFullScreen } = this.state;

    return (
      <>
        <CustomModal
          className="bp-schema-canvas-modal bp-schema-canvas-modal--fullscreen"
          open={isFullScreen}
          isShaded={false}
          onClose={this.handleFullScreenClose}
          shouldUnmount={true}
        >
          <ModalBody>
            <PreserveContent>
              {isFullScreen ? this.renderContent() : null}
            </PreserveContent>
          </ModalBody>
        </CustomModal>

        <CustomModal
          className="bp-schema-canvas-modal"
          open={modal !== null}
          isShaded={false}
          onClose={this.handleContainerCancel}
          size="md"
          shouldUnmount={true}
        >
          <PreserveContent>
            {modal && (
              <>
                <ModalHeader
                  closeModal={this.handleContainerCancel}
                  labelClassName="bp-schema-canvas-modal__label"
                  label={<FormattedMessage id={modal.container.name}/>}
                  titleClassName="bp-schema-canvas-modal__title"
                  title={<FormattedMessage id={modal.container.description}/>}
                />

                <ModalBody>
                  <SchemaContainerSelect
                    assignment={modal}
                    onSelectDevice={this.handleDeviceSelect}
                  />
                </ModalBody>
              </>
            )}
          </PreserveContent>
        </CustomModal>

        <CustomModal
          className="bp-schema-canvas-modal"
          open={preview !== null}
          isShaded={true}
          onClose={this.handleContainerCancel}
          size="md"
          shouldUnmount={true}
        >
          <PreserveContent>
            {preview && (
              <>
                <ModalHeader
                  closeModal={this.handleContainerCancel}
                  labelClassName="bp-schema-canvas-modal__label"
                  label={<FormattedMessage id={preview.container.name}/>}
                  titleClassName="bp-schema-canvas-modal__title"
                  title={<FormattedMessage id={preview.container.description}/>}
                />

                <ModalBody>
                  <SchemaContainerPreview
                    selection={preview.selection}
                  />
                </ModalBody>

                <ModalFooter>
                  <CustomButton
                    kind="secondary"
                    onClick={this.handleContainerCancel}
                    autoFocus={true}
                  >
                    <FormattedMessage id="schemaEdit/containerView/cancel"/>
                  </CustomButton>

                  <CustomButton
                    kind="primary"
                    onClick={() => this.handleContainerChange(preview)}
                  >
                    <FormattedMessage id="schemaEdit/containerView/change"/>
                  </CustomButton>
                </ModalFooter>
              </>
            )}
          </PreserveContent>
        </CustomModal>
      </>
    );
  }

  @bind()
  private handleFullScreenOpen(): void {
    this.setState({ isFullScreen: true });
  }

  @bind()
  private handleFullScreenClose(): void {
    this.setState({ isFullScreen: false });
  }

  @bind()
  private handleCanvasEvent(event: SchemaEventCanvas): void {
    if (event.type === 'zoom') {
      this.handleZoomEvent(event);
    } else if (event.type === 'move') {
      this.handleMoveEvent(event);
    }
  }

  private handleZoomEvent(event: SchemaEventCanvasZoom): void {
    const origin = this.getViewboxPoint(event.point);
    const scale = pipe(
      this.animation.target.a,
      (v) => (event.mode === 'relative' ? v * event.delta : v + event.delta * ZOOM_STEP),
      (v) => (event.mode === 'relative' ? v : Math.round(v / ZOOM_STEP) * ZOOM_STEP),
      (v) => Math.max(ZOOM_MIN, Math.min(ZOOM_MAX, v)),
    );

    this.zoomWithOrigin(origin, scale);
  }

  private handleMoveEvent(event: SchemaEventCanvasMove): void {
    const delta = {
      x: event.delta.x / this.animation.target.a,
      y: event.delta.y / this.animation.target.a,
    };

    const result = matrixMultiply(
      this.animation.target,
      matrixTranslate(delta),
    );

    this.animation.animate(this.applyMatrixConstraint(result));
  }

  @bind()
  private handleZoomIn(): void {
    const { schema } = this.props;

    const origin = this.getViewboxPoint({
      x: schema.width / 2,
      y: schema.height / 2,
    });
    const scale = pipe(
      this.animation.target.a,
      (v) => Math.floor(v / ZOOM_STEP) * ZOOM_STEP + ZOOM_STEP,
      (v) => Math.max(ZOOM_MIN, Math.min(ZOOM_MAX, v)),
    );

    this.zoomWithOrigin(origin, scale);
  }

  @bind()
  private handleZoomOut(): void {
    const { schema } = this.props;

    const origin = this.getViewboxPoint({
      x: schema.width / 2,
      y: schema.height / 2,
    });
    const scale = pipe(
      this.animation.target.a,
      (v) => Math.ceil(v / ZOOM_STEP) * ZOOM_STEP - ZOOM_STEP,
      (v) => Math.max(ZOOM_MIN, Math.min(ZOOM_MAX, v)),
    );

    this.zoomWithOrigin(origin, scale);
  }

  @bind()
  private handleZoomReset(): void {
    this.animation.animate(MATRIX_IDENTITY);
  }

  @bind()
  private handleContainerSelect(assignment: SchemaAssignment): void {
    if (!assignment.selection) {
      this.setState({ modal: assignment });
      return;
    }

    this.setState({
      preview: {
        container: assignment.container,
        selection: assignment.selection,
      },
    });
  }

  @bind()
  private handleContainerChange(assignment: SchemaAssignment): void {
    this.setState({ modal: assignment, preview: null });
  }

  @bind()
  private handleDeviceSelect(assignment: SchemaAssignment): void {
    const { assignments, onChange } = this.props;
    onChange(assignments.map((it) => (it.container.id === assignment.container.id ? assignment : it)));

    this.setState({ modal: null });
  }

  @bind()
  private handleContainerCancel(): void {
    this.setState({ modal: null, preview: null });
  }

  private getViewboxPoint(schemaPoint: Point): Point {
    return pointTransform(
      schemaPoint,
      matrixInverse(this.animation.target),
    );
  }

  private zoomWithOrigin(origin: Point, scale: number): void {
    const result = matrixMultiply(
      this.animation.target,
      matrixScale(scale / this.animation.target.a, origin),
    );

    this.animation.animate(this.applyMatrixConstraint(result));
  }

  private applyMatrixConstraint(matrix: Matrix): Matrix {
    const { schema } = this.props;

    const lt = {
      x: 0,
      y: 0,
    };
    const rb = {
      x: schema.width,
      y: schema.height,
    };

    const realLT = pointTransform(lt, matrix);
    const realRB = pointTransform(rb, matrix);

    const deltaX = realLT.x > lt.x
      ? lt.x - realLT.x
      : realRB.x < rb.x
        ? rb.x - realRB.x
        : 0;
    const deltaY = realLT.y > lt.y
      ? lt.y - realLT.y
      : realRB.y < rb.y
        ? rb.y - realRB.y
        : 0;
    if (deltaX === 0 && deltaY === 0) {
      return matrix;
    }

    return matrixMultiply(
      matrixTranslate({ x: deltaX, y: deltaY }),
      matrix,
    );
  }
}

const ZOOM_MIN = 1.00;
const ZOOM_MAX = 6.00;
const ZOOM_STEP = 0.50;

export const SchemaCanvas = injectIntl(SchemaCanvasWithContext);
