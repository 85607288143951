import './ReportDocumentPageEvaluationCharts.scss';
import { Decimal } from 'decimal.js-light';
import React, { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParametricSelector } from 'src/hooks/useParametricSelector';
import { FormattedBEP } from 'src/modules/common/components/FormattedBEP';
import { FormattedValue } from 'src/modules/common/components/FormattedValue';
import { sub } from 'src/modules/common/constants/formattingHelpers';
import { Project } from 'src/modules/common/types/Project';
import { EvaluationResultsChartsGraphBEP } from 'src/modules/evaluation/views/EvaluationResultsChartsGraphBEP';
import { EvaluationResultsChartsGraphCO2 } from 'src/modules/evaluation/views/EvaluationResultsChartsGraphCO2';
import { EvaluationResultsChartsGraphIRR } from 'src/modules/evaluation/views/EvaluationResultsChartsGraphIRR';
import { EvaluationResultsChartsGraphROI } from 'src/modules/evaluation/views/EvaluationResultsChartsGraphROI';
import { getHvacAssesmentFormData } from 'src/modules/hvac/selectors/getHvacAssesmentFormData';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';
import { ReportDocumentPageAmount } from 'src/modules/report/views/document/ReportDocumentPageAmount';

type Props = {
  readonly document: ReportGenerated;
  readonly formData: ReportFormData;
  readonly project: Project;
};

export const ReportDocumentPageEvaluationCharts = memo(({ document, formData, project }: Props) => {
  const intl = useIntl();

  const hvacAssessmentFormData = useParametricSelector(getHvacAssesmentFormData, project);

  const isOnlyOneAreaEnabled = useMemo(() => {
    const { heating, cooling, ventilation } = hvacAssessmentFormData;

    return [heating.enabled, cooling.enabled, ventilation.enabled].filter(Boolean).length === 1;
  }, [hvacAssessmentFormData]);

  const areCostExclSavingsNegative = (document.evaluation.results.savings.costExclCO2?.overall &&
    !document.evaluation.results.savings.costExclCO2?.overall?.isPositive()) ||
    false;
  const isInvestmentEmpty = Boolean(document.evaluation.results.savings.costExclCO2?.overall?.isPositive() &&
    (project.data.evaluation.investmentAmount === null ||
    project.data.evaluation.investmentAmount?.isZero()));

  return (
    <div className="bp-report-document-page-evaluation-charts">
      {formData.evaluationCharts.showBreakEvenPoint && (
        <div className="bp-report-document-page-evaluation-charts__container">
          <div className="bp-report-document-page-evaluation-charts__container-header">
            <div className="bp-report-document-page-evaluation-charts__container-title">
              <FormattedMessage id="evaluation/charts/BEP/title"/>
            </div>

            <div className="bp-report-document-page-evaluation-charts__container-description">
              <FormattedMessage id="evaluation/charts/BEP/subtitle"/>
            </div>
          </div>

          <EvaluationResultsChartsGraphBEP
            data={document.evaluation.results.charts.BEP}
            xAxisLabel={intl.formatMessage({ id: 'unit/years' })}
            yAxisLabel={intl.formatMessage(
              { id: 'evaluation/charts/BEP/yAxisLabel' },
              { currency: intl.formatMessage({ id: `common/currency/${document.evaluation.currency}` }) },
            )}
            interactive={false}
            areCostSavingsExcludedNegative={areCostExclSavingsNegative}
            isInvestmentEmpty={isInvestmentEmpty}
          />

          <div className="bp-report-document-page-evaluation-charts__container-additionals">
            <ReportDocumentPageAmount
              title={<FormattedMessage id="evaluation/charts/BEP/summary/exclCO2" values={{ sub }}/>}
              amount={<FormattedBEP value={document.evaluation.results.charts.BEP?.years.exclCO2}/>}
              unit={<FormattedMessage id="evaluation/charts/BEP/summary/unit"/>}
            />

            <ReportDocumentPageAmount
              title={<FormattedMessage id="evaluation/charts/BEP/summary/inclCO2" values={{ sub }}/>}
              amount={<FormattedBEP value={document.evaluation.results.charts.BEP?.years.inclCO2}/>}
              unit={<FormattedMessage id="evaluation/charts/BEP/summary/unit"/>}
            />
          </div>
        </div>
      )}

      {formData.evaluationCharts.showAnnualCarbonEmmission && (
        <div className="bp-report-document-page-evaluation-charts__container">
          <div className="bp-report-document-page-evaluation-charts__container-header">
            <div className="bp-report-document-page-evaluation-charts__container-title">
              <FormattedMessage id="evaluation/charts/CO2/title" values={{ sub }}/>
            </div>

            <div className="bp-report-document-page-evaluation-charts__container-description">
              <FormattedMessage id="evaluation/charts/CO2/subtitle" values={{ sub }}/>
            </div>
          </div>

          <EvaluationResultsChartsGraphCO2
            data={document.evaluation.results.charts.CO2}
            interactive={false}
            shouldDisplayOverallData={!isOnlyOneAreaEnabled}
            yAxisLabel={intl.formatMessage(
              { id: 'evaluation/charts/CO2/yAxisLabel' },
              { sub },
            )}
          />
        </div>
      )}

      {formData.evaluationCharts.showReturnOnInvestment && (
        <div className="bp-report-document-page-evaluation-charts__container">
          <div className="bp-report-document-page-evaluation-charts__container-header">
            <div className="bp-report-document-page-evaluation-charts__container-title">
              <FormattedMessage id="evaluation/charts/ROI/title"/>
            </div>

            <div className="bp-report-document-page-evaluation-charts__container-description">
              <FormattedMessage id="evaluation/charts/ROI/subtitle"/>
            </div>
          </div>

          <EvaluationResultsChartsGraphROI
            seria={document.evaluation.results.charts.ROI}
            interactive={false}
            areCostSavingsExcludedNegative={areCostExclSavingsNegative}
            isInvestmentEmpty={isInvestmentEmpty}
          />

          <div className="bp-report-document-page-evaluation-charts__container-additionals">
            <div className="bp-report-document-page-evaluation-charts__table">
              <div className="bp-report-document-page-evaluation-charts__table-header-row">
                <div className="bp-report-document-page-evaluation-charts__table-header-cell">
                  <FormattedMessage id="evaluation/charts/ROI/values/year"/>
                </div>

                <div className="bp-report-document-page-evaluation-charts__table-header-cell">
                  <FormattedMessage id="evaluation/charts/ROI/values/exclCO2/table" values={{ sub }}/>
                </div>

                <div className="bp-report-document-page-evaluation-charts__table-header-cell">
                  <FormattedMessage id="evaluation/charts/ROI/values/inclCO2/table" values={{ sub }}/>
                </div>
              </div>

              {document.evaluation.results.charts.ROI !== null && document.evaluation.results.charts.ROI.map((it) => (
                <div key={it.year.toNumber()} className="bp-report-document-page-evaluation-charts__table-body-row">
                  <div className="bp-report-document-page-evaluation-charts__table-body-cell">
                    {it.year.toString().padStart(2, '0')}
                  </div>
                  <div className="bp-report-document-page-evaluation-charts__table-body-cell">
                    <FormattedValue
                      value={it.value.exclCO2.mul(100)}
                      precision={1}
                    />
                  </div>
                  <div className="bp-report-document-page-evaluation-charts__table-body-cell">
                    <FormattedValue
                      value={it.value.inclCO2.mul(100)}
                      precision={1}
                    />
                  </div>
                </div>
              ))}

              {document.evaluation.results.charts.ROI === null && NO_CHART_DATA.map((it) => (
                <div key={it.year.toNumber()} className="bp-report-document-page-evaluation-charts__table-body-row">
                  <div className="bp-report-document-page-evaluation-charts__table-body-cell">
                    {it.year.toString().padStart(2, '0')}
                  </div>
                  <div className="bp-report-document-page-evaluation-charts__table-body-cell">
                    <FormattedValue
                      value={it.exclCO2}
                      precision={1}
                    />
                  </div>
                  <div className="bp-report-document-page-evaluation-charts__table-body-cell">
                    <FormattedValue
                      value={it.inclCO2}
                      precision={1}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {formData.evaluationCharts.showInternalRateOfReturn && (
        <div className="bp-report-document-page-evaluation-charts__container">
          <div className="bp-report-document-page-evaluation-charts__container-header">
            <div className="bp-report-document-page-evaluation-charts__container-title">
              <FormattedMessage id="evaluation/charts/IRR/title"/>
            </div>

            <div className="bp-report-document-page-evaluation-charts__container-description">
              <FormattedMessage id="evaluation/charts/IRR/subtitle"/>
            </div>
          </div>

          <EvaluationResultsChartsGraphIRR
            seria={document.evaluation.results.charts.IRR}
            interactive={false}
            areCostSavingsExcludedNegative={areCostExclSavingsNegative}
            isInvestmentEmpty={isInvestmentEmpty}
          />

          <div className="bp-report-document-page-evaluation-charts__container-additionals">
            <div className="bp-report-document-page-evaluation-charts__table">
              <div className="bp-report-document-page-evaluation-charts__table-header-row">
                <div className="bp-report-document-page-evaluation-charts__table-header-cell">
                  <FormattedMessage id="evaluation/charts/IRR/values/year"/>
                </div>

                <div className="bp-report-document-page-evaluation-charts__table-header-cell">
                  <FormattedMessage id="evaluation/charts/IRR/values/exclCO2/table" values={{ sub }}/>
                </div>

                <div className="bp-report-document-page-evaluation-charts__table-header-cell">
                  <FormattedMessage id="evaluation/charts/IRR/values/inclCO2/table" values={{ sub }}/>
                </div>
              </div>

              {document.evaluation.results.charts.IRR !== null && document.evaluation.results.charts.IRR.map((it) => (
                <div key={it.year.toNumber()} className="bp-report-document-page-evaluation-charts__table-body-row">
                  <div className="bp-report-document-page-evaluation-charts__table-body-cell">
                    {it.year.toString().padStart(2, '0')}
                  </div>
                  <div className="bp-report-document-page-evaluation-charts__table-body-cell">
                    <FormattedValue
                      value={it.value.exclCO2.mul(100)}
                      precision={1}
                    />
                  </div>
                  <div className="bp-report-document-page-evaluation-charts__table-body-cell">
                    <FormattedValue
                      value={it.value.inclCO2.mul(100)}
                      precision={1}
                    />
                  </div>
                </div>
              ))}

              {document.evaluation.results.charts.IRR === null && NO_CHART_DATA.map((it) => (
                <div key={it.year.toNumber()} className="bp-report-document-page-evaluation-charts__table-body-row">
                  <div className="bp-report-document-page-evaluation-charts__table-body-cell">
                    {it.year.toString().padStart(2, '0')}
                  </div>
                  <div className="bp-report-document-page-evaluation-charts__table-body-cell">
                    <FormattedValue
                      value={it.exclCO2}
                      precision={1}
                    />
                  </div>
                  <div className="bp-report-document-page-evaluation-charts__table-body-cell">
                    <FormattedValue
                      value={it.inclCO2}
                      precision={1}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

const NO_CHART_DATA = [
  new Decimal(1),
  new Decimal(2),
  new Decimal(3),
  new Decimal(4),
  new Decimal(5),
  new Decimal(10),
  new Decimal(20),
].map((year) => ({
  year: year,
  exclCO2: null,
  inclCO2: null,
}));
