import './ReportDocumentPageEvaluationOverview.scss';
import { HvacArea } from '@belimo-retrofit-portal/logic';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { CurrencySymbol } from 'src/modules/common/components/CurrencySymbol';
import { FormattedValue } from 'src/modules/common/components/FormattedValue';
import { SavingsAmount } from 'src/modules/common/components/SavingsAmount';
import { SavingsValue } from 'src/modules/common/components/SavingsValue';
import { SavingTileCard } from 'src/modules/common/components/SavingTileCard';
import { sub } from 'src/modules/common/constants/formattingHelpers';
import { ChartUpIcon } from 'src/modules/common/icons/ChartUpIcon';
import { CurrencyExchangeIcon } from 'src/modules/common/icons/CurrencyExchangeIcon';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';
import { ReportDocumentPageAmount } from 'src/modules/report/views/document/ReportDocumentPageAmount';

type Props = {
  readonly document: ReportGenerated;
  readonly formData: ReportFormData;
};

export const ReportDocumentPageEvaluationOverview = memo(({ document, formData }:Props) => (
  <div className="bp-report-document-page-evaluation-overview">

    {formData.evaluation.showInitialInvestment && (
      <>
        <div className="bp-report-document-page-evaluation-overview__title">
          <FormattedMessage id="evaluation/form/initial/title" values={{ sub }}/>
        </div>

        <div className="bp-report-document-page-evaluation-overview__investments">
          <ReportDocumentPageAmount
            title={<FormattedMessage id="evaluation/form/initial/investmentAmount/label"/>}
            amount={(
              <SavingsAmount
                value={document.evaluation.inputs.investmentAmount}
                currency={document.evaluation.currency}
              />
            )}
            unit={null}
          />

          <ReportDocumentPageAmount
            title={<FormattedMessage id="evaluation/form/initial/carbonEmissionPrice/label" values={{ sub }}/>}
            amount={(
              <SavingsAmount
                value={document.evaluation.inputs.carbonEmissionPrice}
                currency={document.evaluation.currency}
              />
            )}
            unit={null}
          />
        </div>

        {document.evaluation.inputs.proposalDescription && (
          <div className="bp-report-document-page-evaluation-overview__modification">
            <div className="bp-report-document-page-evaluation-overview__title">
              <FormattedMessage id="evaluation/form/proposal/description/label"/>
            </div>

            <div className="bp-report-document-page-evaluation-overview__modification-description">
              {document.evaluation.inputs.proposalDescription}
            </div>
          </div>
        )}
      </>
    )}

    {formData.evaluation.showAnnualSavings && (
      <>
        <div className="bp-report-document-page-evaluation-overview__title">
          <FormattedMessage id="evaluation/savings/title"/>
        </div>

        <div className="bp-report-document-page-evaluation-overview__savings">
          <SavingTileCard
            savingTitle={<FormattedMessage id="evaluation/savings/costExclCO2/title" values={{ sub }}/>}
            icon={<CurrencyExchangeIcon/>}
            amountTitle={(
              <FormattedMessage
                id="evaluation/savings/costExclCO2/subtitle"
                values={{ currency: <CurrencySymbol currency={document.evaluation.currency}/> }}
              />
            )}
            amount={(
              <SavingsAmount
                value={document.evaluation.results.savings.costExclCO2.overall}
                currency={document.evaluation.currency}
              />
            )}
            unit={null}
            small={true}
          >
            <div className="bp-report-document-page-evaluation-overview__saving-area">
              <div className="bp-report-document-page-evaluation-overview__saving-name">
                <FormattedMessage id="evaluation/hvacArea/heating"/>
              </div>

              <div className="bp-report-document-page-evaluation-overview__saving-value">
                <SavingsAmount
                  value={document.evaluation.results.savings.costExclCO2.perArea[HvacArea.HEATING]}
                  currency={document.evaluation.currency}
                />
              </div>
            </div>

            <div className="bp-report-document-page-evaluation-overview__saving-area">
              <div className="bp-report-document-page-evaluation-overview__saving-name">
                <FormattedMessage id="evaluation/hvacArea/cooling"/>
              </div>

              <div className="bp-report-document-page-evaluation-overview__saving-value">
                <SavingsAmount
                  value={document.evaluation.results.savings.costExclCO2.perArea[HvacArea.COOLING]}
                  currency={document.evaluation.currency}
                />
              </div>
            </div>

            <div className="bp-report-document-page-evaluation-overview__saving-area">
              <div className="bp-report-document-page-evaluation-overview__saving-name">
                <FormattedMessage id="evaluation/hvacArea/ventilation"/>
              </div>

              <div className="bp-report-document-page-evaluation-overview__saving-value">
                <SavingsAmount
                  value={document.evaluation.results.savings.costExclCO2.perArea[HvacArea.VENTILATION]}
                  currency={document.evaluation.currency}
                />
              </div>
            </div>
          </SavingTileCard>

          <SavingTileCard
            savingTitle={<FormattedMessage id="evaluation/savings/costInclCO2/title" values={{ sub }}/>}
            icon={<CurrencyExchangeIcon/>}
            amountTitle={(
              <FormattedMessage
                id="evaluation/savings/costInclCO2/subtitle"
                values={{ currency: <CurrencySymbol currency={document.evaluation.currency}/> }}
              />
            )}
            amount={(
              <SavingsAmount
                value={document.evaluation.results.savings.costInclCO2.overall}
                currency={document.evaluation.currency}
              />
            )}
            unit={null}
            small={true}
          >
            <div className="bp-report-document-page-evaluation-overview__saving-area">
              <div className="bp-report-document-page-evaluation-overview__saving-name">
                <FormattedMessage id="evaluation/hvacArea/heating"/>
              </div>

              <div className="bp-report-document-page-evaluation-overview__saving-value">
                <SavingsAmount
                  value={document.evaluation.results.savings.costInclCO2.perArea[HvacArea.HEATING]}
                  currency={document.evaluation.currency}
                />
              </div>
            </div>

            <div className="bp-report-document-page-evaluation-overview__saving-area">
              <div className="bp-report-document-page-evaluation-overview__saving-name">
                <FormattedMessage id="evaluation/hvacArea/cooling"/>
              </div>

              <div className="bp-report-document-page-evaluation-overview__saving-value">
                <SavingsAmount
                  value={document.evaluation.results.savings.costInclCO2.perArea[HvacArea.COOLING]}
                  currency={document.evaluation.currency}
                />
              </div>
            </div>

            <div className="bp-report-document-page-evaluation-overview__saving-area">
              <div className="bp-report-document-page-evaluation-overview__saving-name">
                <FormattedMessage id="evaluation/hvacArea/ventilation"/>
              </div>

              <div className="bp-report-document-page-evaluation-overview__saving-value">
                <SavingsAmount
                  value={document.evaluation.results.savings.costInclCO2.perArea[HvacArea.VENTILATION]}
                  currency={document.evaluation.currency}
                />
              </div>
            </div>
          </SavingTileCard>

          <SavingTileCard
            savingTitle={<FormattedMessage id="evaluation/investment/title" values={{ sub }}/>}
            icon={<ChartUpIcon/>}
            amountTitle={(
              <FormattedMessage
                id="evaluation/investment/subtitle"
                values={{ sub: sub, currency: <CurrencySymbol currency={document.evaluation.currency}/> }}
              />
            )}
            amount={(
              <SavingsValue
                value={document.evaluation.results.investment.carbonEmission}
                precision={0}
              />
            )}
            unit={(
              <FormattedMessage
                id="unit/currencyPerTonne_CO2"
                values={{ sub: sub, currency: <CurrencySymbol currency={document.evaluation.currency}/> }}
              />
            )}
            small={true}
          >
            <div>
              <div className="bp-report-document-page-evaluation-overview__description-title">
                <FormattedMessage id="evaluation/investment/description/title"/>
              </div>

              <div className="bp-report-document-page-evaluation-overview__description-text">
                <FormattedMessage id="evaluation/investment/description/text" values={{ sub }}/>
              </div>
            </div>
          </SavingTileCard>
        </div>
      </>
    )}
  </div>
));
