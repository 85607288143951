import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { LOCALE_MAP } from 'src/modules/common/constants/locale';
import { getConfigState } from 'src/modules/config/selectors/getConfigState';
import { RouterLoader } from 'src/modules/routing/views/RouterLoader';

type Props = {
  readonly children: React.ReactElement;
};

export const ConfigProvider = ({ children }: Props): React.ReactElement | null => {
  const config = useSelector(getConfigState);
  if (config.data.state === 'pending') {
    return <RouterLoader/>;
  }
  if (config.data.state === 'failure') {
    // NOTE: not a good idea but the expected behaviour is not specified
    throw config.data.error;
  }

  const { language, translations } = config.data.value.i18n;
  const locale = LOCALE_MAP[language];

  return (
    <IntlProvider locale={locale} textComponent="span" messages={translations}>
      {children}
    </IntlProvider>
  );
};
