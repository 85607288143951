import { PoObject, PoParser } from '@evoja/po-parser';
import { pipe } from 'fp-ts/function';
import * as D from 'io-ts/Decoder';
import { Translations } from 'src/modules/config/types/Translations';

export const PO_FILE: D.Decoder<unknown, Translations> = pipe(
  D.string,
  D.parse((input) => {
    try {
      const poObject = new PoParser().parse(input);
      return D.success(formatPoObject(poObject));
    } catch (error) {
      return D.failure(input, 'PoFile');
    }
  }),
);

function formatPoObject(poObject: PoObject): Record<string, string> {
  const translations: Record<string, string> = {};

  Object.keys(poObject.translations).forEach((namespace) => {
    Object.keys(poObject.translations[namespace]).forEach((key) => {
      const message = poObject.translations[namespace][key];
      const translationKey = namespace ? `${namespace}:${key}` : key;
      translations[translationKey] = message.msgstr.join('\n');
    });
  });

  return translations;
}
