import './HvacResultSavingsOverall.scss';
import { EnergySource, HvacArea, HvacResults } from '@belimo-retrofit-portal/logic';
import { Information } from '@carbon/icons-react';
import { Stack, Tooltip } from '@carbon/react';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CarbonSavingsUnit } from 'src/modules/common/components/CarbonSavingsUnit';
import { EnergyEfficiencyLabelWrapper } from 'src/modules/common/components/EnergyEfficiencyLabelWrapper';
import { EnergyEfficiencyStack } from 'src/modules/common/components/EnergyEfficiencyStack';
import { EnergySavingsUnit } from 'src/modules/common/components/EnergySavingsUnit';
import { FormattedMathMessage } from 'src/modules/common/components/FormattedMathMessage';
import { SavingsAmount } from 'src/modules/common/components/SavingsAmount';
import { SavingsValue } from 'src/modules/common/components/SavingsValue';
import { SavingTileCard } from 'src/modules/common/components/SavingTileCard';
import { sub, sup } from 'src/modules/common/constants/formattingHelpers';
import { CARBON_THEME } from 'src/modules/common/constants/theme';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { CloudIcon } from 'src/modules/common/icons/CloudIcon';
import { CoolingIcon } from 'src/modules/common/icons/CoolingIcon';
import { FanIcon } from 'src/modules/common/icons/FanIcon';
import { HeatingIcon } from 'src/modules/common/icons/HeatingIcon';
import { LightningIcon } from 'src/modules/common/icons/LightningIcon';
import { BodySmallText } from 'src/modules/common/typography/BodySmallText';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormData } from 'src/modules/hvac/types/HvacFormData';

type Props = {
  readonly form: FormController<HvacFormData, FormError>;
  readonly results: HvacResults;
};

export const HvacResultSavingsOverall = ({ form, results }: Props): React.ReactElement => {
  const intl = useIntl();

  const currency = useFormWatch(form, form.field.at('currency').value.get);
  const heatingEnabled = useFormWatch(form, form.field.at('assessment').at('heating').at('enabled').value.get);
  const coolingEnabled = useFormWatch(form, form.field.at('assessment').at('cooling').at('enabled').value.get);
  const ventilationEnabled = useFormWatch(form, form.field.at('assessment').at('ventilation').at('enabled').value.get);

  return (
    <>
      <Stack orientation="vertical" gap="1.5rem" className="bp-hvac-result-savings-overall">
        <div className="bp-hvac-result-savings-overall__table">
          <div className="bp-hvac-result-savings-overall__row">
            <div className="bp-hvac-result-savings-overall__content"/>

            <div className="bp-hvac-result-savings-overall__assessment">
              <p className="bp-hvac-result-savings-overall__assessment-tab">
                <FormattedMessage id="hvac/form/common/actual"/>
              </p>

              <div className="bp-hvac-result-savings-overall__assessment-divider">&nbsp;</div>

              <p className="bp-hvac-result-savings-overall__assessment-tab">
                <FormattedMessage id="hvac/form/common/future"/>
              </p>
            </div>
          </div>

          <div
            className={clsx(
              'bp-hvac-result-savings-overall__row bp-hvac-result-savings-overall__row--contained',
              heatingEnabled ? null : 'bp-hvac-result-savings-overall__row--disabled',
            )}
          >
            <div className="bp-hvac-result-savings-overall__content">
              <div className="bp-hvac-result-savings-overall__content-icon">
                <HeatingIcon/>
              </div>

              <BodySmallText>
                <FormattedMessage id="hvac/tab/heating"/>
              </BodySmallText>
            </div>

            <div className="bp-hvac-result-savings-overall__assessment">
              <div className="bp-hvac-result-savings-overall__assessment-tab">
                <EnergyEfficiencyLabelWrapper
                  sized={false}
                  value={results.perArea[HvacArea.HEATING].rating.actual}
                  disabled={false}
                />
              </div>

              <div className="bp-hvac-result-savings-overall__assessment-divider">
                <ArrowRightIcon/>
              </div>

              <div className="bp-hvac-result-savings-overall__assessment-tab">
                <EnergyEfficiencyLabelWrapper
                  sized={false}
                  value={results.perArea[HvacArea.HEATING].rating.future}
                  disabled={false}
                />
              </div>
            </div>
          </div>

          <div
            className={clsx(
              'bp-hvac-result-savings-overall__row bp-hvac-result-savings-overall__row--contained',
              coolingEnabled ? null : 'bp-hvac-result-savings-overall__row--disabled',
            )}
          >
            <div className="bp-hvac-result-savings-overall__content">
              <div className="bp-hvac-result-savings-overall__content-icon">
                <CoolingIcon/>
              </div>

              <BodySmallText>
                <FormattedMessage id="hvac/tab/cooling"/>
              </BodySmallText>
            </div>

            <div className="bp-hvac-result-savings-overall__assessment">
              <div className="bp-hvac-result-savings-overall__assessment-tab">
                <EnergyEfficiencyLabelWrapper
                  sized={false}
                  value={results.perArea[HvacArea.COOLING].rating.actual}
                  disabled={false}
                />
              </div>

              <div className="bp-hvac-result-savings-overall__assessment-divider">
                <ArrowRightIcon/>
              </div>

              <div className="bp-hvac-result-savings-overall__assessment-tab">
                <EnergyEfficiencyLabelWrapper
                  sized={false}
                  value={results.perArea[HvacArea.COOLING].rating.future}
                  disabled={false}
                />
              </div>
            </div>
          </div>

          <div
            className={clsx(
              'bp-hvac-result-savings-overall__row bp-hvac-result-savings-overall__row--contained',
              ventilationEnabled ? null : 'bp-hvac-result-savings-overall__row--disabled',
            )}
          >
            <div className="bp-hvac-result-savings-overall__content">
              <div className="bp-hvac-result-savings-overall__content-icon">
                <FanIcon/>
              </div>

              <p className="bp-hvac-result-savings-overall__row-name">
                <FormattedMessage id="hvac/tab/ventilation"/>
              </p>
            </div>

            <div className="bp-hvac-result-savings-overall__assessment">
              <div className="bp-hvac-result-savings-overall__assessment-tab">
                <EnergyEfficiencyLabelWrapper
                  sized={false}
                  value={results.perArea[HvacArea.VENTILATION].rating.actual}
                  disabled={false}
                />
              </div>

              <div className="bp-hvac-result-savings-overall__assessment-divider">
                <ArrowRightIcon/>
              </div>

              <div className="bp-hvac-result-savings-overall__assessment-tab">
                <EnergyEfficiencyLabelWrapper
                  sized={false}
                  value={results.perArea[HvacArea.VENTILATION].rating.future}
                  disabled={false}
                />
              </div>
            </div>
          </div>

          <div className="bp-hvac-result-savings-overall__row bp-hvac-result-savings-overall__row--contained">
            <div className="bp-hvac-result-savings-overall__content">
              <BodySmallText className="bp-hvac-result-savings-overall__row-name--aligned">
                <FormattedMessage id="hvac/tab/overall"/>
              </BodySmallText>
            </div>

            <div className="bp-hvac-result-savings-overall__assessment">
              <div className="bp-hvac-result-savings-overall__assessment-tab">
                <div className="bp-hvac-result-savings-overall__assessment-stack">
                  <EnergyEfficiencyStack
                    line={true}
                    value={results.overall.rating.actual}
                  />
                </div>
              </div>

              <div className="bp-hvac-result-savings-overall__assessment-divider">&nbsp;</div>

              <div className="bp-hvac-result-savings-overall__assessment-tab">
                <div className="bp-hvac-result-savings-overall__assessment-stack">
                  <EnergyEfficiencyStack
                    line={true}
                    value={results.overall.rating.future}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Stack>

      <div className="bp-hvac-result-savings-overall__cards">
        <SavingTileCard
          amount={(
            <SavingsValue
              value={results.overall.savings.thermal.relative?.mul(100) ?? null}
              precision={0}
            />
          )}
          amountTitle={intl.formatMessage({ id: 'hvac/result/savings/potential/thermalSavings/diagram/label' })}
          icon={<HeatingIcon/>}
          savingTitle={intl.formatMessage({ id: 'hvac/result/savings/potential/thermalSavings/title' })}
          unit={<FormattedMessage id="unit/percent"/>}
          small={false}
        >
          <div className="bp-hvac-result-savings-overall__saving-area">
            <div className="bp-hvac-result-savings-overall__saving-description">
              <FormattedMessage id="hvac/result/savings/potential/thermalSavings/heating"/>
            </div>

            <div className="bp-hvac-result-savings-overall__saving-value">
              <SavingsValue
                value={results.perArea[HvacArea.HEATING].savings.thermal.absolute?.div(1_000) ?? null}
                precision={0}
              />
            </div>
            <div className="bp-hvac-result-savings-overall__saving-unit">
              <EnergySavingsUnit source={results.perArea[HvacArea.HEATING].savings.thermal.source}/>
            </div>
          </div>

          <div className="bp-hvac-result-savings-overall__saving-area">
            <div className="bp-hvac-result-savings-overall__saving-description">
              <FormattedMessage id="hvac/result/savings/potential/thermalSavings/cooling"/>
            </div>

            <div className="bp-hvac-result-savings-overall__saving-value">
              <SavingsValue
                value={results.perArea[HvacArea.COOLING].savings.thermal.absolute?.div(1_000) ?? null}
                precision={0}
              />
            </div>
            <div className="bp-hvac-result-savings-overall__saving-unit">
              <EnergySavingsUnit source={results.perArea[HvacArea.COOLING].savings.thermal.source}/>
            </div>
          </div>

          <div className="bp-hvac-result-savings-overall__saving-area">
            <div className="bp-hvac-result-savings-overall__saving-description">
              <FormattedMessage id="hvac/result/savings/potential/thermalSavings/annual"/>
            </div>

            <div className="bp-hvac-result-savings-overall__saving-value">
              <SavingsAmount
                value={results.overall.savings.thermal.annual}
                currency={currency}
              />
            </div>
          </div>
        </SavingTileCard>

        <SavingTileCard
          amount={(
            <SavingsValue
              value={results.overall.savings.electrical.relative?.mul(100) ?? null}
              precision={0}
            />
          )}
          amountTitle={intl.formatMessage({ id: 'hvac/result/savings/potential/electricalSavings/diagram/label' })}
          icon={<LightningIcon/>}
          savingTitle={intl.formatMessage({ id: 'hvac/result/savings/potential/electricalSavings/title' })}
          unit={<FormattedMessage id="unit/percent"/>}
          small={false}
        >
          <div className="bp-hvac-result-savings-overall__saving-area">
            <div className="bp-hvac-result-savings-overall__saving-description">
              <FormattedMessage id="hvac/result/savings/potential/electricalSavings/energy"/>
            </div>

            <div className="bp-hvac-result-savings-overall__saving-value">
              <SavingsValue
                value={results.overall.savings.electrical.absolute?.div(1_000) ?? null}
                precision={0}
              />
            </div>
            <div className="bp-hvac-result-savings-overall__saving-unit">
              <EnergySavingsUnit source={EnergySource.ELECTRICITY}/>
            </div>
          </div>

          <div className="bp-hvac-result-savings-overall__saving-area">
            <div className="bp-hvac-result-savings-overall__saving-description">
              <FormattedMessage id="hvac/result/savings/potential/electricalSavings/annual"/>
            </div>

            <div className="bp-hvac-result-savings-overall__saving-value">
              <SavingsAmount
                value={results.overall.savings.electrical.annual}
                currency={currency}
              />
            </div>
          </div>
        </SavingTileCard>

        <SavingTileCard
          amount={(
            <SavingsValue
              value={results.overall.savings.carbon.relative?.mul(100) ?? null}
              precision={0}
            />
          )}
          amountTitle={
            intl.formatMessage(
              { id: 'hvac/result/savings/potential/carbonDioxide/diagram/label' },
              { sub, sup },
            )
          }
          icon={<CloudIcon/>}
          savingTitle={intl.formatMessage({ id: 'hvac/result/savings/potential/carbonDioxide/title' }, { sub, sup })}
          unit={(
            <div className="bp-hvac-result-savings-overall__card-unit">
              <FormattedMessage id="unit/percent"/>

              <Tooltip
                align="top"
                enterDelayMs={0}
                label={(
                  <FormattedMathMessage
                    id="hvac/result/savings/potential/carbonDioxide/tooltip/content"
                    values={{ sub, sup }}
                  />
                )}
              >
                <Information color={CARBON_THEME.onSurfaceLightPrimary} size={18}/>
              </Tooltip>
            </div>
          )}
          small={false}
        >
          <div className="bp-hvac-result-savings-overall__saving-area">
            <div className="bp-hvac-result-savings-overall__saving-description">
              <FormattedMessage id="hvac/result/savings/potential/carbonDioxide/thermal" values={{ sub }}/>
            </div>

            <div className="bp-hvac-result-savings-overall__saving-value">
              <SavingsValue
                value={results.overall.savings.carbon.thermal?.div(1_000_000) ?? null}
                precision={0}
              />
            </div>
            <div className="bp-hvac-result-savings-overall__saving-unit">
              <CarbonSavingsUnit/>
            </div>
          </div>

          <div className="bp-hvac-result-savings-overall__saving-area">
            <div className="bp-hvac-result-savings-overall__saving-description">
              <FormattedMessage id="hvac/result/savings/potential/carbonDioxide/electrical" values={{ sub }}/>
            </div>

            <div className="bp-hvac-result-savings-overall__saving-value">
              <SavingsValue
                value={results.overall.savings.carbon.electrical?.div(1_000_000) ?? null}
                precision={0}
              />
            </div>
            <div className="bp-hvac-result-savings-overall__saving-unit">
              <CarbonSavingsUnit/>
            </div>
          </div>
        </SavingTileCard>
      </div>
    </>
  );
};
