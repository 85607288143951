import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { getProjectLazy } from 'src/modules/common/selectors/getProjectLazy';
import { getCurrentDate } from 'src/modules/config/selectors/getCurrentDate';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';

export const getReportFormData = createSelector([
  getProjectLazy,
  getCurrentDate,
], (project, currentDate): ReportFormData => (
  {
    ...project.data.report,
    cover: {
      ...project.data.report.cover,
      creationDate: project.data.report.cover.creationDate || dayjs(currentDate).startOf('day').toDate(),
    },
    energy: project.data.report.energy,
    hvac: project.data.report.hvac,
    schema: project.data.report.schema,
  }
));
